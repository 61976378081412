<template>
  <div class="page-wrap purple darken-3">
    <div class="signup-wrapper">
      <base-card>
        <v-card-text class="text-center px-5 py-7">
          <v-avatar
            tile
            width="150"
            height="auto"
            class="mb-4 mx-auto"
          >
            <img
              src="@/assets/images/logo.png"
              alt="Servercentralen sign up"
            >
          </v-avatar>

          <h5>{{ $t('userAuth.welcomeToJoin') }}</h5>

          <v-form
            ref="form"
            class="mt-5"
            v-model="validForm"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <div class="font-weight-bold black--text text-left">{{ $t("partner.companyInfo") }}</div>
                <v-divider class="mb-4" />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="companyName"
                  :label="`${$t('partner.companyName')}*`"
                  :rules="requiredFieldRules"
                />

                <v-text-field
                  v-model="taxId"
                  class="mt-2"
                  :label="`${$t('partner.taxId')}*`"
                  :rules="requiredFieldRules"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0 mt-2"
              >
                <v-text-field
                  v-model="phoneNumber"
                  :label="`${$t('common.phoneNumber')}*`"
                  :rules="requiredFieldRules"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0 mt-2"
              >
                <v-text-field
                  v-model="partnerCode"
                  :label="`${$t('partner.partnerCode')}*`"
                  :rules="partnerCodeRules"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0 mt-5"
              >
                <div class="font-weight-bold black--text text-left">{{ $t("partner.adminUserInfo") }}</div>
                <v-divider class="mb-4" />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0"
              >
                <v-text-field
                  v-model="firstName"
                  :label="`${$t('common.firstName')}*`"
                  :rules="requiredFieldRules"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-0"
              >
                <v-text-field
                  v-model="lastName"
                  :label="`${$t('common.lastName')}*`"
                  :rules="requiredFieldRules"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0 mt-2"
              >
                <v-text-field
                  v-model="email"
                  :label="`${$t('common.email')}*`"
                  type="email"
                  :rules="emailRules"
                />

                <v-text-field
                  v-model="password"
                  class="mt-2"
                  :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isShowPassword ? 'text' : 'password'"
                  :label="`${$t('common.password')}*`"
                  :rules="passwordRules"
                  @click:append="isShowPassword = !isShowPassword"
                />

                <v-text-field
                  v-model="confirmPassword"
                  class="mt-2"
                  :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isShowPassword ? 'text' : 'password'"
                  :label="`${$t('common.confirmPassword')}*`"
                  :rules="confirmPasswordRules"
                  @click:append="isShowPassword = !isShowPassword"
                />
              </v-col>
            </v-row>

            <v-alert
              v-if="errorMessage"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="mt-2 text-left"
              dismissible
            >
              {{ errorMessage }}
            </v-alert>

            <v-btn
              block
              color="primary"
              class="mt-4"
              :disabled="!validForm"
              :loading="isLoading"
              @click="submitForm"
            >
              {{ $t("userAuth.signUp") }}
            </v-btn>

            <div class="d-flex align-center justify-center mt-4">
              <span class="purple--text text-subtitle-1">
                {{ $t("userAuth.alreadyHaveAccount") }}?
              </span>
              <v-btn text small class="font-weight-bold text-subtitle-2" to="/signin" color="primary">
                {{ $t("userAuth.login") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  export default {
    name: 'PartnerRegister',
    metaInfo: {
      title: 'Partner Register',
    },

    data () {
      return {
        isShowPassword: false,
        validForm: true,
        isLoading: false,
        errorMessage: '',
        companyName: '',
        taxId: '',
        phoneNumber: '',
        partnerCode: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        requiredFieldRules: [
          (v) => !!v || this.$t('error.requiredField'),
        ],
        partnerCodeRules: [
          (v) => !!v || this.$t('error.requiredField'),
          (v) => /^[a-zA-Z]+$/.test(v) || this.$t('error.invalidPartnerCode'),
        ],
        emailRules: [
          (v) => !!v || this.$t('error.requiredField'),
          (v) => /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
        passwordRules: [
          (v) => !!v || this.$t('error.requiredField'),
          (v) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || this.$t('error.invalidPassword'),
        ],
        confirmPasswordRules: [
          (v) => !!v || this.$t('error.requiredField'),
          (v) => v === this.password || this.$t('error.confirmPasswordNotMatch'),
        ],
      }
    },

    mounted() {
      this.token = this.$route.params.token || ''

      if (!this.token) {
        this.$router.push('/signin')
      }

      if (this.token) {
        this.loadInviteInfo()
      }
    },

    methods: {
      ...mapActions({
        fetchInviteInfo: 'fetchInviteInfo',
        registerPartner: 'registerPartner',
      }),

      async loadInviteInfo() {
        try {
          const response = await this.fetchInviteInfo({ token: this.token })

          this.companyName = response.companyName
          this.email = response.email
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          await showErrorDialog(this.$t('common.error'), errorText)

          this.$router.push('/signin')
        }
      },

      async submitForm () {
        this.errorMessage = ''

        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.isLoading = true
        try {
          const response = await this.registerPartner({
            token: this.token,
            companyName: this.companyName,
            taxId: this.taxId,
            code: this.partnerCode,
            phoneNumber: this.phoneNumber,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
          })

          await showSuccessDialog(
            this.$t('common.success'),
            this.$t('partner.contactAdminToAllow')
          )

          window.location.href = response.partnerUrl
        } catch (error) {
          this.errorMessage = util.getErrorResponse(error)
        }
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.page-wrap {
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.signup-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
</style>
